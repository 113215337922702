import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout'
import H1 from '../components/H1'

const MikroklimatUla = () => (
  <Layout>
    <H1>Mikroklimat Ula</H1>
    <p>W ostatnim okresie czasu można spotkać coraz więcej informacji w opracowaniach naukowych z dziedziny apiterapii na temat leczniczego działania mikroklimatu ula.</p>
    <p>Mikroklimat ula ma działanie lecznicze. Wewnątrz ula jest ciepło i temperatura waha się od 25-28 C, panuje tam wysoka wilgotność ,a mimo to warunki te nie pozwalają rozwijać się drobnoustrojom. Co potwierdziły przeprowadzone badania naukowe. Nie stwierdzono tam żadnych bakterii, wirusów czy chorobotwórczych grzybów.
    W tym celu buduje się specjalne pomieszczenia pozwalające pacjentom w sposób bezpieczny bez obawy o pożądlenia przez pszczoły oddychać wydobywającym się powietrzem z ula.
    Inhalacje powietrzem ulowym maja właściwości lecznicze i skutecznie leczą astmę .alergie, katar sienny ,grypy, przeziębienia, anginy ,migreny, zapalenia oskrzeli płuc, gardła i uszu, Powietrze ulowe wykazuje także działanie relaksacyjne, dobrze wpływa na drogi oddechowe stany stresowe i napięciowe.
    Zmyślą o naszych klientach zapraszamy do nowo wybudowanego, takiego pomieszczenia, które znajduje się na terenie naszego gospodarstwa.</p>
  </Layout>
)

export default MikroklimatUla
